import { Table, Drawer, Spin, Button, Row, Col } from 'antd';
import type { TourProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import * as S from '../Student.styles';
import { doList } from 'store/slices/studentSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  HoursByEducativeLevel,
  initialValues,
  Student,
  StudentCsv,
} from '../types';
import { WorkCenter } from '../../workcenters/types';
import { ColumnsType } from 'antd/es/table';
import { filterProps } from 'components/common/FilterProps';
import { OptionsDrawerBar } from '../StudentDetail/OptionsDrawerBar';
import { StudentDetail, StudentHours } from '../StudentDetail/StudentDetail';
import { InfoTour } from 'components/common/InfoTour/InfoTour';
import { InfoButton } from 'components/common/buttons/Button/InfoButton';
import { CheckAccess } from 'checkAccess/CheckAccess';
import { PermissionsToAccess } from 'checkAccess/ConstPermissions';
import { useTourCheck } from 'hooks/useTourCheck';
import { STUDENTS_PATH } from 'components/router/AppRouter';
import { useNavigate } from 'react-router-dom';
import { StudentChangeWorkCenterModal } from './StudentChangeWorkCenterModal';
import { DownloadTableButton } from 'components/common/buttons/Button/DownloadTableButton';

const { writeStudents } = PermissionsToAccess;

export const StudentList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { students, status: studentStateStatus } = useAppSelector(
    (state) => state.student
  );

  const [studentData, setStudentData] = useState<Student[]>();
  const [studentDataCsv, setStudentDataCsv] = useState<StudentCsv[]>();
  const [selectedRowData, setSelectedRowData] = useState<Student>();
  const [spin, setSpin] = useState<boolean>(true);
  const [updatingTable, setUpdatingTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const HandleOpenModal = (open: boolean) => setOpenModal(open);

  useEffect(() => {
    if (studentStateStatus === 'fulfilled') {
      setUpdatingTable(true);
      dispatch(doList()).then(() => {
        setUpdatingTable(false);
      });
    }
  }, [dispatch]);

  // tour
  const { setOpenTour, openTour, isFirstTime } = useTourCheck('student');
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const steps: TourProps['steps'] = [
    {
      title: 'Sección de alumnos',
      description:
        'En esta sección podrá consultar la información de las alumnas y alumnos que participan en Educación Dual.',
      target: () => ref1.current,
    },
    {
      title: 'Nuevo alumno',
      description:
        'Para agregar un nuevo estudiante deberá presionar el botón “Nuevo alumno“ y agregar los datos correspondientes.',
      placement: 'left',
      target: () => ref2.current,
    },
    {
      title: 'Tabla, filtros y búsqueda',
      description:
        'Para realizar la búsqueda de estudiantes ya registrados podrá hacer uso de los filtros que aparecen en el encabezado de la tabla',
      placement: 'bottom',
    },
  ];

  // Carga la lista de estudiantes
  useEffect(() => {
    if (studentStateStatus !== 'fulfilled') {
      setSpin(true);
      dispatch(doList());
    } else {
      setSpin(false);
    }
  }, [studentStateStatus, students]);

  useEffect(() => {
    setStudentData(students);
  }, [students]);

  useEffect(() => {
    if (studentData && studentData.length > 0) {
      const csvData: StudentCsv[] = studentData.map((student) => {
        const formattedHours =
          student.completedHours
            ?.map((hoursObj) => {
              return `${hoursObj.educativeLevel}: ${hoursObj.completedHours}`;
            })
            .join(', ') ?? '';
        return {
          name: student.name ?? '',
          paternalLastname: student.paternalLastname ?? '',
          maternalLastname: student.maternalLastname ?? '',
          curp: student.curp ?? '',
          subsystem: student.workcenter?.subsystemShort ?? 'Sin subsistema',
          workcenter: student.workcenter?.nameShort ?? 'Sin plantel',
          academicStatus: student.academicStatus,
          completedHours: formattedHours,
          grade: student.grade,

        };
      });
      if (csvData) {
        setStudentDataCsv(csvData);
      }
    }
  }, [studentData]);

  // Drawer
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setSelectedRowData(initialValues);
    setOpen(false);
  };

  const columns: ColumnsType<Student> = [
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: Student, b: Student) => a.name.localeCompare(b.name),
      ...filterProps,
      onFilter: (value, record) => {
        return record.name
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: 'Apellido paterno',
      dataIndex: 'paternalLastname',
      key: 'paternalLastname',
      sorter: (a: Student, b: Student) =>
        (a.paternalLastname ?? '').localeCompare(b.paternalLastname ?? ''),
      ...filterProps,
      onFilter: (value, record) =>
        (record.paternalLastname ?? '')
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: 'Apellido materno',
      dataIndex: 'maternalLastname',
      key: 'maternalLastname',
      sorter: (a: Student, b: Student) =>
        (a.maternalLastname ?? '').localeCompare(b.maternalLastname ?? ''),
      ...filterProps,
      onFilter: (value, record) =>
        (record.maternalLastname ?? '')
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: 'CURP',
      dataIndex: 'curp',
      key: 'curp',
      ...filterProps,
      onFilter: (value, record) => {
        return record.curp
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b: Student) =>
        a.curp && b.curp
          ? a.curp.localeCompare(b.curp)
          : a.curp
          ? a.curp.localeCompare('')
          : b.curp
          ? b.curp.localeCompare('')
          : ''.localeCompare(''),
    },
    {
      title: 'Subsistema / IES',
      dataIndex: 'workcenter',
      key: 'subsystem',
      render: (data: WorkCenter) => {
        return data ? data.subsystemShort : '';
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.workcenter?.subsystemShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b: Student) =>
        a.workcenter?.subsystemShort.localeCompare(
          b.workcenter?.subsystemShort
        ),
    },
    {
      title: 'Plantel',
      dataIndex: 'workcenter',
      key: 'workcenter',
      render: (data: WorkCenter) => {
        return data ? data.nameShort : '';
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.workcenter?.nameShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b: Student) =>
        a.workcenter?.nameShort.localeCompare(b.workcenter?.nameShort),
    },
    {
      title: 'Estado académico',
      dataIndex: 'academicStatus',
      key: 'academicStatus',
      align: 'center',
      render: (status: string) => {
        const textColor = S.colorStatus(status);
        let showStatus = status;
        if (status.toLowerCase() === 'baja') {
          showStatus = 'Baja académica';
        }
        return (
          <p style={{ color: textColor }}>{showStatus ?? 'Sin registro'}</p>
        );
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.academicStatus
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b: Student) =>
        a.academicStatus.localeCompare(b.academicStatus),
    },
    {
      title: 'Horas completadas',
      dataIndex: 'completedHours',
      key: 'completedHours',
      align: 'center',
      render: (completedHours: HoursByEducativeLevel[]) => {
        return StudentHours(completedHours);
      },
    },
    {
      title: 'Grado',
      dataIndex: 'grade',
      key: 'grade',
      align: 'center'
    },
  ];

  return (
    <>
      <S.Wrapper>
        <S.Card
          id='students-list'
          title={
            <>
              <span ref={ref1}>
                Alumnos de educación dual{' '}
                <InfoButton onClick={() => setOpenTour(true)} />
              </span>
            </>
          }
          extra={
            CheckAccess(writeStudents) && (
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <Col>
                  {updatingTable ? (
                    <div>
                      <Spin />
                      <strong> Actualizando tabla</strong>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col>
                  <Button type='primary' onClick={() => HandleOpenModal(true)}>
                    Cambiar alumno de plantel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    ref={ref2}
                    onClick={() => {
                      navigate(`${STUDENTS_PATH}/nuevo`);
                    }}
                  >
                    Nuevo alumno
                  </Button>
                </Col>
                <Col>
                  <DownloadTableButton
                    columns={columns}
                    data={studentDataCsv}
                    size='middle'
                    type='primary'
                    label='Descargar registros'
                  />
                </Col>
              </Row>
            )
          }
          padding='1.25rem'
        >
          <Drawer
            title='Detalle del Alumno Dual'
            placement='right'
            width='50%'
            onClose={onCloseDrawer}
            destroyOnClose
            open={open}
            extra={
              CheckAccess(writeStudents) && (
                <OptionsDrawerBar
                  rowSelected={selectedRowData ?? initialValues}
                />
              )
            }
          >
            <StudentDetail selectedRow={selectedRowData ?? initialValues} />
          </Drawer>
          <StudentChangeWorkCenterModal
            openModal={openModal}
            OpenModalHanlder={HandleOpenModal}
          />
          <Spin spinning={spin} delay={400} tip='Cargando...' size='large'>
            <Table
              dataSource={studentData}
              columns={
                CheckAccess(writeStudents)
                  ? columns
                  : columns.filter((col) => col.title !== 'Acción')
              }
              onRow={(record, _rowIndex) => {
                return {
                  onClick: (_event) => {
                    setSelectedRowData(record);
                    showDrawer();
                  },
                };
              }}
              tableLayout='fixed'
              size='middle'
              rowKey='_id'
            />
          </Spin>
        </S.Card>
        <InfoTour
          onClose={() => setOpenTour(false)}
          open={openTour}
          steps={steps}
          block='nearest'
          module='student'
          isFirstTime={isFirstTime}
        />
      </S.Wrapper>
    </>
  );
};
